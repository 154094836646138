import React, { useEffect, useState } from 'react';
import { Card, Typography, Divider, Alert, message } from 'antd';
import { getJobDescriptionService } from '../service';
import { formatDate, isNotEmpty } from '../function';
import CustomButton from '../common/CustomButton';
import { data } from 'autoprefixer';

const { Title,Text, Paragraph } = Typography;

interface JobDetails {
  companyProjectName: string;
  alias: string;
  expireTime: string;
  jdContent: string;
}

const LandingPage = () => {
  let code = window.location.search?.substring(1);

  const [expireDate, setExpireDate] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [mailAlias, setMailAlias] = useState('')
  const [jobPosition, getJobPosition] = useState('')
  const [location, getLocation] = useState('')
  const [jobResponsibilities, getJobResponsibilities] = useState('')
  const [jobRequirements, setJobRequirements] = useState('')
  const [employmentType, getEmploymentType] = useState('')
  const [company_overview, getCompanyOverview] = useState('')
  const [job_overview, getJobOverview] = useState('')
  const [salary, getSalary] = useState('')
  const [benefit, getBenefits] = useState('')
  const [work_conditions, getWorkConditions] = useState('')
  const [linkCompany, setLinkCompany] = useState('')
  const [showLearnMore, setShowLearnMore] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  const handleCopy = async (mail:string) => {
    await navigator.clipboard.writeText(mail);
    message.success('The email has been copied.')
  }

  const handleLearnMore = ()=>{
    window.open(linkCompany, '_blank');
  }

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener('resize', handleResize);

    const getJobDes = async () => {
      if (code.includes('&')){
        code = code.split('&')?.[0]
        if(code.includes('=')){
          code = code.split('=')?.[0]
        }
      }
      const body = {
        code: code
      }
      try {
        
        const response = await getJobDescriptionService(body);
        setMailAlias(response?.data?.alias)
        getJobPosition(response?.data?.job_position)
        getLocation(response?.data?.localtion)
        getEmploymentType(response?.data?.employment_type)
        getCompanyOverview(response?.data?.company_overview)
        const link = response?.data?.link_company
        if(isNotEmpty(link)){
          if ((link as string).startsWith('http')){
            setLinkCompany(response?.data?.link_company)
          }else{
            setLinkCompany(`https://${response?.data?.link_company}`)
          }
          
        }
        
        setShowLearnMore(response?.data?.link_company ? true: false)
        getJobOverview(response?.data?.job_overview)

          if(response?.data?.job_responsibilities.includes('-'))
          {
            let tmp = response?.data?.job_responsibilities as string
            let array = tmp.split('-')
            let tmp2 = ''
            for (const item of array){
              if (isNotEmpty(item?.trim())){
                tmp2 += `${item}`
              }
              
            }
            getJobResponsibilities(tmp2)
          }else{
            getJobResponsibilities(response?.data?.job_responsibilities)
          }
        
        setJobRequirements(response?.data?.job_requirements)
        getSalary(response?.data?.salary)
        getBenefits(response?.data?.benefits)
        getWorkConditions(response?.data?.work_conditions)


        setCompanyName(response?.data?.company_project_name)
        setExpireDate(formatDate(response?.data?.expire_time))
      } catch (error) {
        console.error('Error fetching response:', error);
      }
    };

    getJobDes();

    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  return (
    <div className="container mx-auto p-4 ">
      <Card className='shadow-md'>
        <Title level={2}>{companyName}</Title>
        <Divider />
        <div><strong>Job Title: </strong> <Text rootClassName='text-lg font-bold' className='text-blue-500'>{jobPosition}</Text></div>

        {/* 1 */}
        <div className='mt-5'><strong>Location: </strong> <Text>{location}</Text></div>

        {/* 2 */}
        <div className='mt-2'><strong>Employment type: </strong> <Text>[{employmentType}]</Text></div>

        {/* 3 */}
        <div>
        <div className="flex items-center mt-8 space-x-2">
        <Title level={4} className='mr-2'>About Us</Title>
        
        {showLearnMore && (
          <a href={linkCompany} target="_blank" rel="noopener noreferrer" className="inline-flex items-center text-blue-500 underline mb-2 ml-5">[Learn more...]</a>
        )}
        </div>
        
        <div className="border border-blue-700-alpha shadow-md rounded-md pl-2 pr-2 pt-3 pb-3">
        <div className="flex items-center justify-between">
      <Paragraph style={{ whiteSpace: 'pre-wrap' }} className="flex-1">{company_overview}</Paragraph>
    
    </div>
        
        </div>
        </div>

        {/* 4 */}
        <div>
        <Title className='mt-5' level={4}>Job Overview</Title>

        <div className="border border-blue-700-alpha shadow-md rounded-md pl-2 pr-2 pt-3 pb-3">
        <Paragraph style={{ whiteSpace: 'pre-wrap' }} >{job_overview}</Paragraph>
        </div>
        </div>


        {/* 5 */}

        <div>
        <Title className='mt-5' level={4}>Key responsibility</Title>

        <div className="border border-blue-700-alpha shadow-md rounded-md pl-2 pr-2 pt-3 pb-3">
        <Paragraph style={{ whiteSpace: 'pre-wrap' }} >{jobResponsibilities}</Paragraph>
        </div>
        </div>


        {/* 5 */}

        <div>
        <Title className='mt-5' level={4}>Requirements</Title>

        <div className="border border-blue-900-alpha rounded-md pl-2 pr-2 pt-3 pb-3">
        <Paragraph style={{ whiteSpace: 'pre-wrap' }} >{jobRequirements}</Paragraph>
        </div>
        </div>
        
        <div>
        <Title className='mt-5' level={4}>Compensation and Benefits</Title>

        <div className="border border-blue-700-alpha shadow-md rounded-md pl-2 pr-2 pt-3 pb-3 mb-10 " >
        <ul>
          <li> 
            <div><strong>Salary: </strong> {salary}</div>
          </li>
          <br></br>
          <li> 
            <div><strong>Benefits: </strong> {benefit}</div>
          </li>
          <br></br>
          <li> 
            <div><strong>Work Conditions: </strong> {work_conditions}</div>
          </li>
        </ul>
        </div>
        </div>
        
        <div className='flex gap-4 flex-col'>
        <Alert 
        description={
          <div className={`flex items-center ${isMobile ? 'flex-col' : undefined} `}> 
            <span className="flex-grow">
              Please submit your CV (in either PDF or DOC format) to:
              <span className="font-semibold mx-1">{mailAlias}</span>
            </span>
            <CustomButton classNameCustom={`justify-center ${isMobile ? 'mt-5' : 'ml-5'}`} key='button' onClick={() => handleCopy(mailAlias)}>
              Copy
            </CustomButton>
          </div>
          }
          type="info"
          showIcon
          className="flex items-center"  
        />

        <div className='ml-2'>
        <ul>
          <li>
           - After receiving your CV, we will notify you of the results within a maximum of 3 days. Please only apply once to avoid being marked as spam.
          </li>
          <li>{`- The application deadline for this position is ${expireDate}`}</li>
        </ul>
        </div>
        </div>
      </Card>
    </div>
  );
};

export default LandingPage;