import { getJobDescription, updateSheet } from "../api/core";
import { IBodyGetJob, IUpdateSheet } from "../api/core/interface";

export const getJobDescriptionService = async (body: IBodyGetJob) => {
  try {
    const response = await getJobDescription(body)
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const updateSheetService = async (body: IUpdateSheet) => {
  try {
    const response = await updateSheet(body)
    return response?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}