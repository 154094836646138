import { Modal, Result } from 'antd'
import Button from './CustomButton'
import { useTranslation } from 'react-i18next'

interface IModalSuccessProps {
  isOpen?: boolean
  titleSuccess?: string
  textButtonConfirm?: string
  status?: 'success' | 'error' | 'info' | 'warning'
  onCloseModalSuccess: () => void
  textChildren?: string,
  textBottom?: string
}

const CustomModalSuccess: React.FC<IModalSuccessProps> = ({
  isOpen,
  titleSuccess,
  textButtonConfirm,
  status = 'success',
  onCloseModalSuccess,
  textChildren,
  textBottom
}) => {
  const { t } = useTranslation()

  const handleCancel = () => {
    onCloseModalSuccess()
  }

  return (
    <Modal
      open={isOpen}
      style={{ minWidth: '40%' }}
      footer={null}
      onCancel={handleCancel}
      className={'w-4/5 md:w-3/5 '}
    >
      <Result
        className="py-2"
        status={status}
        title={ <span className="text-xl text-black font-semibold">
          {'Submit successfull!'}
        </span>}
        subTitle={
          <>
            <div className=" text-base">{titleSuccess}</div>
            <div className=" text-base">{textChildren}</div>
            <div className=" text-base">
            
            </div>
          </>
        }
        extra={[
          <>
            <Button key={textButtonConfirm} onClick={handleCancel}>
              {textButtonConfirm}
            </Button>
          </>
        ]}
      />
    </Modal>
  )
}

export default CustomModalSuccess
