import React, { useEffect, useState } from 'react';
import { Card, Typography, Divider, Alert, message } from 'antd';
import { getJobDescriptionService } from '../service';
import { formatDate } from '../function';
import CustomButton from '../common/CustomButton';

const { Title,Text, Paragraph } = Typography;

const Info = () => {

 
  const handleLearnMore = ()=>{
    window.location.href = 'https://socool.ai';
  }

  useEffect(() => {
    
  }, []); 

  return (
    <div className='w-full flex items-center justify-center'>
        <div className="mt-10 pb-5 bg-gray-100 flex items-center justify-center border border-blue-700-alpha rounded-md w-fa/2 md:w-auto px-10">
        
        <div>
        <div>
        <Title className='mt-8' level={5}>Ready to recruit faster and smarter?</Title>
        </div>
        <div>
        <Title className='mt-8' level={5}>Take a peek at SoCool.ai and see how cutting-edge technology</Title>
        
        </div>
        <div>
        <Title level={5}>can transform your hiring process!</Title>
        </div>

        <div className='flex justify-end'>
        <CustomButton onClick={handleLearnMore} classNameCustom='bg-[#C0C0C0]' >Learn more</CustomButton>
        </div>
        </div>
        
        
      
    </div>
    </div>
  );
};

export default Info;