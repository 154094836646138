import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
const MAX_RETRY_COUNT = 3;

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retryCount?: number;  // Optional retry count property
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://api.socool.ai', // Replace with your API base URL
  timeout: 20000, // Example timeout configuration
  headers: {
    'Content-Type': 'application/json',
    // 'accept-language': currentLanguage
  },
});

// Request interceptor for adding authorization token
axiosInstance.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    // Perform actions before request is sent
    // const accessToken = localStorage.getItem('access_token');
    // if (accessToken) {
    //   // Ensure config.headers is initialized before assigning Authorization header
    //   config.headers = {
    //     ...config.headers,
    //     Authorization: `Bearer ${accessToken}`,
    //   } as any;
    // }
    return config;
  },
  (error: AxiosError) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor for handling responses
axiosInstance.interceptors.response.use(
  async (response: AxiosResponse<any>) => {
    // Handle success responses globally
   
    return response;
  },
  async (error: AxiosError) => {
    // Handle error responses globally
    
    return Promise.reject(error);
  }
);


export default axiosInstance;
