import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import LandingPage from '../componentsNew/LandingPage'
import Info from '../componentsNew/Info'
import NewForm from '../componentsNew/NewForm'

const AppRouters = () => {
  return (
    <Routes>
    
      <Route path="/job" element={<LandingPage />} />
      <Route path="/" element={<Info />} />
      <Route path="/form-1" element={<NewForm/>}/>
      <Route path="/form-2" element={<NewForm/>}/>
      {/* <Route path="/form-3" element={<NewForm/>}/>
      <Route path="/form-4" element={<NewForm/>}/> */}
    </Routes>
  )
}

export default AppRouters
