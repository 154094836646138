import dayjs from "dayjs"


export const formatDate = (date:string) => {
  try{
    if (isNotEmpty(date)){
      const formattedDate = dayjs(date).format('DD MMM YYYY');
      return formattedDate;
    }
    return ''
  }catch(error){
    console.log(error)
    return ''
  }  
}

export const formatDate2 = (date:string) => {
  try{
    if (isNotEmpty(date)){
      const formattedDate = dayjs(date).format('HH:mm:ss');
      return formattedDate;
    }
    return ''
  }catch(error){
    console.log(error)
    return ''
  }  
}

export const isNotEmpty = (text:string): boolean => {
  if (text == undefined || text == null || text == ''){
    return false
  }
  return true
}

export const isValidEmail = (email:string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  console.log(emailRegex.test(email))
  return emailRegex.test(email);
};

