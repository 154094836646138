import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Typography } from 'antd';
import { logoHiringFast } from '../assets';

const { Title, Text } = Typography;

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let currentEmail = localStorage.getItem('current_emails_count') || 0
  

  useEffect(() => {
    const refresh = () => {
      currentEmail = localStorage.getItem('current_emails_count') || 0
    }
    
    refresh()
  },[])

  return (
    <div className="fixed w-full bg-[#F4F4F4] z-50 px-2 shadow-md flex items-center justify-between">
      {/* Logo and Title */}
      <div className="flex items-center justify-center flex-grow">
        <a href="/">
          <Avatar src={<img src={logoHiringFast} alt="avatar" />} className="lg:size-20 size-18" />
        </a>
        <a href="/">
          <h1 className="bg-[#26526b] text-transparent bg-clip-text text-center py-3 font-bold text-xl sm:text-4xl">
            {t('HiringFast.net')}
          </h1>
        </a>
        <div className='mt-5 ml-2'>
        <strong>powered by SoCool.ai</strong>
        </div>
      </div>
      
    </div>
  );
};

export default Header;
