
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouters from './routers';
import './i18n'
import Header from './components/Header';
function App() {

  return (
    <div className="App">
        <div className=''>
          <div className='flex flex-col bg-grey-900'>
            <BrowserRouter>
              <Header/>
              <div className='mt-28 md:mt-[80px] w-full h-[calc(100vh-80px)]' >
                <AppRouters/>
              </div>
            </BrowserRouter>
          </div>
        </div>
      </div>
  );
}

export default App;
