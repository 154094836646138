import { Form, Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { AppleOutlined, BankOutlined, InfoCircleOutlined, LaptopOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { isValidEmail } from '../function';

interface CustomInputProps {
    children?: React.ReactNode;
    classNameCustom?: string;
    type?: 'full_name' | 'company_name' | 'job_position' | 'email2' | 'phone';
    placeHolder: string,
    label: string
}

const CustomInput: React.FC<CustomInputProps> = ({ children, classNameCustom, type = "primary", placeHolder, label }) => {

    useEffect(() => {

    }, []);

    return (
        <Form.Item
            name={type}
            className='md:w-[950px]'
            label={
                <span style={{ display: 'flex', color: '#000000', fontWeight:'bold'}}>
                    {label}
                </span>
            }
            colon={false}
            rules={[
                { required: true, message: 'Please answer this question!' },
                {
                    validator(_, value) {
                        const maxLength = 100;
                        if (value && value.length === maxLength) {
                            return Promise.reject(
                                new Error(`You have reached the maximum length of ${maxLength} characters!`)
                            );
                        }
                        if (type === 'phone' && value.length !== 10){
                            return Promise.reject(
                                new Error(`Invalid phone number format!`)
                            );
                        }
                        if (type === 'email2' && !isValidEmail(value)){
                            return Promise.reject(
                                new Error(`Invalid email format!`)
                            );
                        }
                        
                        return Promise.resolve();
                    },
                },
            ]}
        >
            <Input
                type={type === 'phone' ? 'number': type}
                
                placeholder={placeHolder}
                className={`md:w-[950px] h-[40px] ${classNameCustom}`}
                prefix={type == 'full_name' ? <UserOutlined className='mr-2' style={{ color: 'rgba(0,0,0,.25)' }} /> : 
                        type == 'company_name' ? <BankOutlined className='mr-2' style={{ color: 'rgba(0,0,0,.25)' }}/> : 
                        type == 'job_position' ? <LaptopOutlined className='mr-2' style={{ color: 'rgba(0,0,0,.25)' }}/> :
                        type == 'email2' ? <MailOutlined className='mr-2' style={{ color: 'rgba(0,0,0,.25)' }}/> : 
                    <PhoneOutlined className='mr-2'  style={{ color: 'rgba(0,0,0,.25)' }}/>}
                suffix={
                    <Tooltip title="Extra information">
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                }
            >

                {children}
            </Input>
        </Form.Item>

    );
};

export default CustomInput;
