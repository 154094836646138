import React, { useEffect, useState } from 'react';
import CustomInput from '../common/CustomInput';
import CustomButton from '../common/CustomButton';
import { Form } from 'antd';
import axios from 'axios';
import { gapi } from 'gapi-script';
import { updateSheet } from '../api/core';
import {google} from 'googleapis'
import {GoogleAuth} from 'google-auth-library'
import CustomModalSuccess from '../common/CustomModalSuccess';
import { formatDate, formatDate2 } from '../function';



const NewForm: React.FC = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const siteKey = "YOUR_SITE_KEY"; // Replace with your actual site key
  const key = '6LeXNHsqAAAAABUJl9q2hPvZ-lRDLohGJER-WU_1';
  const key2 = '6Lc8Mn0qAAAAAE2aO5X-ClO7WVVPIDd7DdUz8Nsd'
  const apiKey = 'AIzaSyD7wDbRrtQf1lXZ9SGHAs83REK8nTy5bBo';
  const [captchaToken, setCaptchaToken] = useState('');
  const tokenCaptcha = localStorage.getItem('token_captcha')
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  let authInstance:any

  const initClient = async () => {
  await gapi.client.init({
      apiKey: apiKey,
      clientId: '776344907801-1oo53fopk8ei5a2786fq1lfgcf8iljpu.apps.googleusercontent.com',
      discoveryDocs: [
        'https://sheets.googleapis.com/$discovery/rest?version=v4',
      ],
      scope: 'https://www.googleapis.com/auth/spreadsheets',
    }).then(() => {
      console.log('start')
      authInstance = gapi.auth2.getAuthInstance();
      
      authInstance.isSignedIn.listen(setIsSignedIn);

      // signIn();
      

      // if (!authInstance.isSignedIn.get()) {
      //   signIn();
        
      // }
      
    }).catch((error:any) => console.error("Error loading GAPI client:", error));
  };

  const signIn = () => {
    console.log(`authInstance:${authInstance}`)
    authInstance.signIn().then(() => {
      console.log('signin')
      // After successful sign-in, check the sign-in status again
      setIsSignedIn(authInstance.isSignedIn.get());
    }).catch((error:any) => console.error("Sign-in failed:", error));
  };

  const [form] = Form.useForm();

  const createAccessment = async () => {
    console.log(`token: ${tokenCaptcha}`);
    let url = `https://recaptchaenterprise.googleapis.com/v1/projects/consultant-recru-1731315776699/assessments?key=${apiKey}`;
    const response = await axios.post(url, {
      "event": {
        "token": tokenCaptcha,
        "expectedAction": "LOGIN",
        "siteKey": key,
      }
    });

    console.log(response);
  };


  const handleSubmit = async () => {
    setIsLoading(true)
    createAccessment();
    const fullname = form?.getFieldValue('full_name');
    const companyName = form?.getFieldValue('company_name');
    const job_position = form?.getFieldValue('job_position');
    const email = form?.getFieldValue('email2');
    const phone = form?.getFieldValue('phone');

    const from = window.location.pathname

    let values :string[] = []
    values.push(formatDate(new Date().toLocaleDateString()))
    values.push(formatDate2(new Date().toISOString()))
    values.push(fullname)
    values.push(companyName)
    values.push(job_position)
    values.push(email)
    values.push(phone)
    values.push(from)

    let test = []
    test.push(values)

    
    const response = await updateSheet({row:values})
    
    setIsModalSuccess(true)
    setIsLoading(false)

    console.log(`response: ${response}`)
  };

  const onCallBack = (token: string) => {
    console.log(`onCallBack: ${token}`);
    if (token) {
      setCaptchaToken(token);
    }

  };
  const handleCloseModalSuccess = () => {
    window.location.reload()
  }

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    gapi.load('client:auth2', initClient);
    // Google reCAPTCHA script should already be loaded
  }, []);

  return (
    <div className="flex flex-col items-center justify-center mt-2">
      {/* The reCAPTCHA widget */}
      <div >
      <div className={isMobile ? `md:w-[950px] text-left ml-10 mr-10 mb-10`: 'md:w-[950px] text-left ml-40 mr-40 mb-10'}>
      Bạn đang ở đây, chứng tỏ bạn là người có tham vọng và tầm nhìn xa.
      <br/>
      <br/>

Bạn muốn nâng tầm giá trị cho công ty của mình – từ văn hóa, con người đến sản phẩm. Để đạt được mục tiêu này, những nhân tài về công nghệ và tài chính là điều không thể thiếu.
<br/>
<br/>
Công nghệ và tài chính là hai lĩnh vực then chốt nhưng rất khó để tuyển được người vừa giỏi chuyên môn, vừa phù hợp với văn hóa công ty. Để tuyển đúng người, bạn cần kiến thức và kinh nghiệm trong các lĩnh vực này để đánh giá năng lực và thu hút nhân tài. Việc chọn sai nhân sự không chỉ gây tốn kém mà còn tiềm ẩn rủi ro pháp lý, ảnh hưởng đến hoạt động kinh doanh hàng ngày.
<br/>
<br/>
Chúng tôi không phải là nhà tuyển dụng. Chúng tôi đơn giản là những người có kinh nghiệm trong lĩnh vực công nghệ và tài chính, chúng tôi sẽ hướng dẫn bạn cách thu hút đúng người đúng việc. 
<br/>
<br/>
Hãy để lại thông tin liên hệ, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất. Cảm ơn bạn đã quan tâm!
      </div>
      </div>
      

      <Form className={isMobile ? 'mr-auto ml-10': undefined}  form={form} onFinish={handleSubmit} layout="vertical">
        <CustomInput classNameCustom="mt-5" placeHolder="Enter your full name" label="Full Name" type="full_name" />
        <CustomInput classNameCustom="mt-5" placeHolder="Enter your company name" label="Company Name" type="company_name" />
        <CustomInput classNameCustom="mt-5" placeHolder="Enter job position" label="Job Position" type="job_position" />
        <CustomInput classNameCustom="mt-5" placeHolder="Enter your email" label="Your Email" type="email2" />
        <CustomInput classNameCustom="mt-5" placeHolder="Enter your phone number" label="Phone Number" type="phone" />
        <div
          className="g-recaptcha mt-5 flex items-center justify-center"
          data-sitekey={key}
          data-callback={onCallBack} 
          data-theme="dark"
          data-action='submit'
        >
        
        </div>
        <CustomButton htmlType="submit" classNameCustom="flex items-end justify-end" loading = {isLoading}>
          Submit
        </CustomButton>
       
      </Form>

      <CustomModalSuccess
        isOpen={isModalSuccess}
        titleSuccess=''
        textChildren=''
        textBottom=''
        textButtonConfirm='Done'
        onCloseModalSuccess={handleCloseModalSuccess}
      />
    </div>
  );
};

export default NewForm;
